.rewards {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3rem;
  gap: 3rem;
  position: relative;
}

.r_sqr1, .r_sqr2 {
  position: absolute;
  pointer-events: none;
}

.r_sqr1 {
  left: 5%;
  bottom: 2%;
  transform: rotate(15deg);
}

.r_sqr2 {
  right: 3%;
  bottom: 35%;
  transform: rotate(45deg);
}

.rewards_head {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.head_lines {
  width: 20%;
}

.rewards_head h1 {
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 3rem;
  color: white;

}

.rewards_mid {
  display: flex;
  width: 70%;
  margin-top: 3rem;
  justify-content: space-between;
  align-items: center;
}

.reward_two {
  display: flex;
  transform: translateY(-40%);
  margin-top: 2.5rem;
}

.reward_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 25%;
}

.rewards_cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
}

.reward_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  background: linear-gradient(180deg, #000000 6.01%, #05eafa32 82.06%, #1cacd046 100%);
  box-shadow: 0px 4px 53px #05eafa2b;
}

.reward_circle h2 {
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 3.5rem;
  color: white;

}

.rewards_cont h1 {
  color: #ffffff;
  font-family: var(--primary-font);
  font-weight: 500; 
  font-size: 2rem;

}

.rewards_cont p {
  color: #ffffff;
  font-family: var(--primary-font);
  font-weight: 500; 
  font-size: 1rem;
  text-align: center;
}

.rewards_foot {
  display: flex;
  flex-direction: column;
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
}



@media only screen and (max-width: 1300px) {
  .rewards_mid {
    width: 80%;
  }
}

@media only screen and (max-width: 1200px) {
  .rewards_mid {
    width: 90%;
  }

  .rewards_head h1 {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 1100px) {
  .rewards_mid {
    width: 100%;
  }

  .rewards_head {
    gap: 1rem;
  }

  .rewards_head h1 {
    font-size: 2.5rem;
  }

  .rewards_cont h1 {
    font-size: 1.6rem;
  
  }
  
  .rewards_cont p { 
    font-size: 0.9rem;
  }
  
}


@media screen and (max-width: 992px) {
  .rewards {
    padding: 1rem 3rem;
    margin-top: 2rem;
  }
  .rewards_head {
    gap: 0.5rem;
  }

  .rewards_head h1 {
    font-size: 2.3rem;
  }

  .rewards_cont h1 {
    font-size: 1.4rem;
  
  }
  
  .rewards_cont p { 
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 800px) {
  .r_sqr1, .r_sqr2 {
    display: none;
  }
  .reward_circle {
    width: 100px;
    height: 100px;
  }
  
  .reward_circle h2 {
    font-size: 3rem;  
  }
  
  .rewards_cont h1 {
    font-size: 1.3rem;
  
  }
  
  .rewards_cont p { 
    font-size: 0.8rem;
  }

  .reward_info {
    width: 30%;
  }
  
}

@media screen and (max-width: 600px) {
  .rewards {
    padding: 0 2.5rem;
  }
  .rewards_head h1 {
    font-size: 1.8rem;
    text-align: center;
  }

  .rewards_mid {
    flex-direction: column;
    width: 100%;
    margin-top: 0rem;
    justify-content: center;
    gap: 2rem;
  }

  .reward_two {
    display: flex;
    transform: translateY(0%);
    margin-top: 0rem;
  }

  .reward_info {
    width: 50%;
  }
}

@media screen and (max-width: 400px) {
  .rewards {
    padding: 0.5rem;
    gap: 1.5rem;
    margin-top: 3rem;
  }

  .reward_info {
    width: 70%;
  }

  
}

