.benefits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3rem;
    gap: 2rem;
    margin-top: 3rem;
    position: relative;
}

.b_sqr1, .b_sqr2 {
    position: absolute;
    pointer-events: none;
}

.b_sqr1 {
    left: 5%;
}

.b_sqr2 {
    right: 3%;
    top: 5%;
    transform: rotate(30deg);
}

.benefits_content {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1.2rem;
    color: white;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
}

.benefits_point {
    display: flex;
    gap: 1.5rem;
}

.benefits_para {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
}

.benefits_icon {
    font-size: 1.3rem;
    color: white;
    flex-shrink: 0;
}

@media screen and (max-width: 992px) {
    .benefits_content {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .b_sqr1, .b_sqr2 {
        display: none;
    }
    .benefits_para {
        font-size: 1rem;
    }

    .benefits_content {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (max-width: 600px) {

    .benefits_para {
        font-size: 0.9rem;
    } 

    .benefits_content {
        margin-top: 0.5rem;
    }

    .benefits {
        padding: 2.5rem;
    }

    .benefits_icon {
        font-size: 1rem;
        color: white;
        flex-shrink: 0;
    }
}

@media screen and (max-width: 400px) {

}