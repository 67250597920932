@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lakki+Reddy&display=swap);
* {
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-padding: 80px;
  scroll-behavior: smooth;
}

:root {
  --primary-font: 'Montserrat', sans-serif; 
  --secondary-font: 'Nunito Sans', sans-serif; 
}

.navbar {
    height: 80px;
    position: fixed;
    /* padding: 0 2rem; */
    z-index: 999;
    top: 0;
    width: 100%;
    transition: ease 0.2s;
  }
  .navbar__main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 3rem;  
  }

  .nav_logo img {
    /* height: 100%; */
    width: 130px;
    margin-top: 0.5rem;
  }
  
  .nav_title {
    font-family: var(--primary-font);
    font-size: 1.75rem;
    font-weight: 700;
    color: white;
  }
  
  .nav_contents {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
    /* gap: 2rem; */
  }
  
  .nav__link {
    grid-gap: 2.5rem;
    gap: 2.5rem;
    font-family: var(--primary-font);
    font-style: normal;
    align-items: center;
    color: #ffffff;
    font-size: 1.1rem;
    cursor: pointer;
    opacity: 0.8;
    font-weight: 500;
  }
  
  .nav__link:hover {
    opacity: 1;
  }
  
  a {
    text-decoration: none;
  }
  
  .nav_hamburger {
    display: none;
  }
  
  .avatarIcon {
    cursor: pointer;
  }
  
  .nav__logout_btn{
    background-color: #001C3D;
    color: #ffff;
    display: flex;
    padding: 0.5rem;
    border-radius: 10px;
    border-color: aqua;
    cursor:pointer;
    font-size: 0.9rem;
  }
  
  @media screen and (max-width: 1200px) {
    .nav_contents {
      grid-gap: 1rem;
      gap: 1rem;
    }
    .nav__link {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 992px) {
    .nav_hamburger {
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      color: white;
    }
  
    .nav_contents {
      display: none;
    }
  
    .nam_menu_icon {
      font-size: 1.75rem;
      color: var(--tertiary-color);
    }
  
    .navbar__mob {
      background: linear-gradient(180deg, #050D18 82.03%, #001C3D 100%);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 0rem 7rem;
      position: relative;
    }
  
    .navbar_mob_close {
      position: absolute;
      top: 1.7rem;
      right: 2rem;
      font-size: 2rem;
      color: white;
    }

    .close_nav_icon {
      cursor: pointer;
    }
  
    .navbar__mobcontents {
      flex: 0.6 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 3rem;
      gap: 3rem;
    }
  
    .navmob__link {
      grid-gap: 2.5rem;
      gap: 2.5rem;
      font-family: var(--primary-font);
      font-style: normal;
      align-items: center;
      color: #ffffff;
      font-size: 1.3rem;
      cursor: pointer;
      opacity: 0.6;
      text-align: center;
    }
    
  
    .navmob__link:hover {
      opacity: 1;
    }
    .nav__logout_btn{
      background-color: #001C3D;
      color: #ffff;
      display: flex;
      padding: 0.5rem;
      border-radius: 10px;
      border-color: aqua;
      cursor:pointer;
      font-size: 0.9rem;
      text-transform: capitalize;
    }
  }
  
  @media screen and (max-width: 600px) {
    .navbar__main {
      padding: 1.5rem 1rem;
  }
  }
  @media screen and (max-width: 600px) {
    .navbar__mob {
      padding: 0 5rem;
      position: relative;
    }
    .navbar_mob_close {
      position: absolute;
      top: 1.7rem;
      right: 2rem;
      font-size: 2rem;
      color: white;
    }
    
  }
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(180deg, #050D18 82.03%, #001C3D 100%);
}

.loader_box1 {
    display: flex;
    height: 400px;
    width: 400px;
    justify-content: center;
    align-items: center;
    rotate: 45deg;
    border: 4px solid #FFFFFF;
    box-shadow:  0px 4px 4px #21F4FE;
    /* filter: blur(20px); */
    border-radius: 35px;
}

.loader_box2 {
    display: flex;
    height: 350px;
    width: 350px;
    justify-content: center;
    align-items: center;
    /* rotate: 45deg; */
    border: 2px solid #FFFFFF;
    box-shadow: inset 0px 4px 4px #21F4FE;
    -webkit-filter: drop-shadow(0px 4px 40px #21F4FE);
            filter: drop-shadow(0px 4px 40px #21F4FE);
    border-radius: 35px;
}

.loader_box2 h1 {
    rotate: -45deg;
    font-family: var(--primary-font);
    color: #FFFFFF;
    font-weight: 600;
    font-size: 2.5rem;
}

@media screen and (max-width: 600px) {
    .loader_box1 {
        height: 300px;
        width: 300px;
    }
    
    .loader_box2 {
        height: 250px;
        width: 250px;
    }
    
    .loader_box2 h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .loader_box1 {
        height: 200px;
        width: 200px;
        border: 2px solid #FFFFFF;

    }
    
    .loader_box2 {
        height: 150px;
        width: 150px;
        border: 1px solid #FFFFFF;

    }
    
    .loader_box2 h1 {
        font-size: 1.5rem;
    }
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}




.home_sec {
  min-height: 100vh;
  background-image: url(/static/media/home_bg.33671cef.svg);
  background-position: center;
  /* background-repeat: no-repeat;  */
  background-size: contain;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
}

.home {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 3rem;
  width: 90%;
}
.referal_btn {
  width: 300px;
  font-family: var(--primary-font);
  font-size: 1.2rem;
  font-weight: 600;
  background: #050912;
  color: #ffff;
  border: 4.1922px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.tmd_icon {
  cursor: pointer;
  color: #ffffff;
  font-size: 1.25rem;
  margin-left: 0.5rem;
  width: 10%;
  flex-shrink: 0;
}

.home_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  width: 100%;
}

.holo-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  height: 130px;
  font-family: "Lakki Reddy", cursive;
}
.holo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.holo.holo-1 {
  -webkit-transform: translateX(150px);
          transform: translateX(150px);
  color: #ffffff;
}
.holo.holo-2 {
  color: #050912;
}
.holo .text {
  position: absolute;
  font-size: 7rem;
  font-weight: bold;
  letter-spacing: 0.5rem;
}

.holo .text.t-left {
  color: #86cbb1;
  -webkit-transform: translate(-4px, -2px);
          transform: translate(-4px, -2px);
}
.holo .text.t-right {
  color: #1295d2;
  -webkit-transform: translate(4px, 2px);
          transform: translate(4px, 2px);
}

.reg_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  font-family: var(--primary-font);
  width: 220px;
  height: 70px;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #050912;
  border: 4.1922px solid #ffffff;
  border-radius: 10px;
  position: relative;
  z-index: 9;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin: 1rem 0;
  cursor: pointer;
}

.reg_btn::before {
  content: "";
  position: absolute;
  width: 220px;
  height: 70px;
  border-radius: 10px;
  background: #ffffff;
  z-index: -1;
  top: 10px;
  left: 10px;
  -webkit-transform: translateZ(-1px);
          transform: translateZ(-1px);
  cursor: pointer;
}
.amb_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  font-family: var(--primary-font);
  width: 220px;
  height: 60px;
  font-size: 1.15rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #050912;
  border: 4.1922px solid #ffffff;
  border-radius: 10px;
  position: relative;
  z-index: 9;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  margin: 2rem 0;
  cursor: pointer;
}

.reg_btn:hover {
  /* scale: 1.05; */
  -webkit-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);

  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
}

.home_highlights {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features {
  width: 80%;
  display: flex;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
  flex-direction: row;
  font-family: var(--primary-font);
  color: white;
}
.home_highlights h3 {
  font-size: 2rem;
  font-weight: 600;
}
.home_highlights h4 {
  font-size: 1.2rem;
  font-weight: 500;
}

.count_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 5px solid #080c17;
  box-shadow: 0px 2.13071px 9.5882px 4.26142px rgba(255, 255, 255, 0.1);
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  background: #ffffff;
  color: #1295d2;
}

.features_cont {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 230px;
  height: 120px;
  border-radius: 10px 10px 0px 0px;
  background: #1295d2;
  padding: 2rem 0;
}

.features_cont h4 {
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center;
  letter-spacing: 0.055em;
  color: #050912;
}

.feature_foot {
  width: 230px;
  height: 20px;
  background: #86cbb1;
  border-top: 5px solid #050912;
  border-radius: 0px 0px 10px 10px;
}

@media only screen and (max-width: 1081px) {
  .count_circle {
    width: 70px;
    height: 70px;
  }
  /*         
    .home{
        padding-top: 2.5rem;
    } */

  .features {
    width: 90%;
  }

  .features_cont {
    width: 210px;
    height: 90px;
  }
  .feature_foot {
    width: 210px;
    height: 20px;
  }

  .home_highlights h3 {
    font-size: 1.5rem;
  }
  .home_highlights h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }
  .holo .text {
    font-size: 6rem;
  }
  .holo-container {
    height: 110px;
  }
}
@media only screen and (max-width: 980px) {
  .home {
    flex-direction: column;
    padding-top: 0;
  }
  .mascot {
    height: 37vh;
    width: 50vw;
  }
  .holo.holo-1 {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    color: #ffffff;
  }

  .holo .text {
    font-size: 5rem;
  }
  .holo-container {
    height: 90px;
  }
}

@media screen and (max-width: 800px) {
  .main {
    min-height: 100vh;
  }
  .home_sec {
    padding: 0 1.5rem;
  }
  .home {
    max-width: 93vw;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
  }

  .mascot {
    height: 35vh;
    width: 45vw;
    padding: 0;
  }
  .features {
    flex-direction: column;
    align-items: center;
    min-width: 93vw;
    min-height: 35vh;
  }
  .box {
    padding: 2rem 0;
  }
  .home_highlights {
    align-items: center;
  }

  .home_title {
    padding-bottom: 2rem;
  }

  .reg_btn {
    /* width: 150px; */
    font-size: 1rem;
  }
  .reg_btn::before {
    /* width: 150px; */
  }
  .count_circle {
    width: 65px;
    height: 65px;
  }

  .features_cont {
    width: 210px;
    /* height: 65px; */
  }
  .feature_foot {
    width: 210px;
    height: 20px;
  }
  .home_highlights h3 {
    font-size: 1.4rem;
  }
  .home_highlights h4 {
    font-size: 1rem;
    font-weight: 500;
  }
  .holo .text {
    font-size: 3.5rem;
  }
  .holo-container {
    height: 70px;
  }
}
@media only screen and (max-width: 600px) {
  .main {
    min-height: 100vh;
  }
  .reg_btn {
    font-size: 1rem;
  }
  .home {
    max-width: 93vw;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
  }

  .mascot {
    height: 30vh;
    width: 85vw;
    padding: 0;
  }
  .features {
    flex-direction: column;
    align-items: center;
  }

  .count_circle {
    width: 60px;
    height: 60px;
  }

  .features_cont {
    width: 200px;
    /* height: 60px; */
  }
  .feature_foot {
    width: 200px;
    height: 20px;
  }
  .box {
    padding: 2rem 0;
  }
  .home_highlights {
    align-items: center;
  }

  .holo .text {
    font-size: 2.75rem;
  }
  .holo-container {
    height: 50px;
  }
}

@media screen and (max-width: 400px) {
  .main {
    min-height: 100vh;
  }
  .home {
    max-width: 93vw;
    padding-top: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .reg_btn {
    font-size: 1rem;
  }
  .mascot {
    height: 30vh;
    width: 93vw;
    padding: 0;
  }
  .features {
    flex-direction: column;
    align-items: center;
    min-width: 93vw;
  }
  .box {
    padding: 2rem 0;
  }
  .home_highlights {
    align-items: center;
  }

  .count_circle {
    width: 50px;
    height: 50px;
  }

  .features {
    width: 90%;
  }

  .features_cont {
    width: 190px;
    height: 85px;
  }
  .feature_foot {
    width: 190px;
    height: 20px;
  }

  .home_highlights h3 {
    font-size: 1.2rem;
  }
  .home_highlights h4 {
    font-size: 1rem;
  }
}

.phno__dialog_container{
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    padding: 2rem;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
}

.phno__dialog_container h1 {
    font-size: 1.25rem;
    font-family: var(--primary-font);
    font-weight: 500;
    color: #050912;
}

.phone_dialog_form{
    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}
.phone__no_input{
    width: 10rem;
    height: 2.5rem;
    border-radius: 10px;
    width: 250px;
    padding: 0.5rem 0.8rem;
    font-family: var(--primary-font);
    color: #050912;
    background: #FFFFFF;
    border: 2px solid #050912;
    outline: none;
    font-size: 1.05rem;
    font-weight: 600;
}

.submit_btn {
    border: 2px solid #080C17;
    font-family: var(--primary-font);
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    box-shadow: 0px 2.13071px 9.5882px 4.26142px rgba(255, 255, 255, 0.1);
    background: #080C17;
    font-weight: 500;
    color: #1295D2;
    width: 100px;
    padding: 0.5rem;
}
/* #footer {
    background: #03012C;
  } */
  
  .footer__main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    flex-direction: column;
  }
  
  .footer-social {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2.5rem 0 1rem 0;
  }
  
  .contact_social_icon {
    font-size: 1.25rem;
  }
  
  @media only screen and (max-width: 768px) {
    .footer-social {
      flex-direction: column;
      grid-gap: 3rem;
      gap: 3rem;
    }
  
    .footer-social-socialmedia {
      order: 2;
    }
    .footer-social-college {
      order: 1;
    }
    .footer-social-event {
      order: 3;
    }
  }
  
  .footer-social-socialmedia {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
  }
  
  .footer-social-socialmedia a {
    text-decoration: none;
    cursor: pointer;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #ffff;
    transition: all 0.2s ease-out;
  }
  
  .footer-social-socialmedia a:hover {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
  }
  
  .mec__logo {
    width: 10rem;
  }
  .excel__logo {
    width: 10rem;
  }
  
  .footer-madewith {
    color: #ffffff;
    font-size: 1rem;
    font-family: var(--primary-font);
  }
  
  .footer_heart {
    margin: 0 0.25rem;
    position: relative;
    top: 3px;
    color: #21b8cb;
  }
.rewards {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 3rem;
  grid-gap: 3rem;
  gap: 3rem;
  position: relative;
}

.r_sqr1, .r_sqr2 {
  position: absolute;
  pointer-events: none;
}

.r_sqr1 {
  left: 5%;
  bottom: 2%;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
}

.r_sqr2 {
  right: 3%;
  bottom: 35%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.rewards_head {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin-bottom: 2rem;
}

.head_lines {
  width: 20%;
}

.rewards_head h1 {
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 3rem;
  color: white;

}

.rewards_mid {
  display: flex;
  width: 70%;
  margin-top: 3rem;
  justify-content: space-between;
  align-items: center;
}

.reward_two {
  display: flex;
  -webkit-transform: translateY(-40%);
          transform: translateY(-40%);
  margin-top: 2.5rem;
}

.reward_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  width: 25%;
}

.rewards_cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.reward_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  background: linear-gradient(180deg, #000000 6.01%, #05eafa32 82.06%, #1cacd046 100%);
  box-shadow: 0px 4px 53px #05eafa2b;
}

.reward_circle h2 {
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 3.5rem;
  color: white;

}

.rewards_cont h1 {
  color: #ffffff;
  font-family: var(--primary-font);
  font-weight: 500; 
  font-size: 2rem;

}

.rewards_cont p {
  color: #ffffff;
  font-family: var(--primary-font);
  font-weight: 500; 
  font-size: 1rem;
  text-align: center;
}

.rewards_foot {
  display: flex;
  flex-direction: column;
  font-family: var(--primary-font);
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
}



@media only screen and (max-width: 1300px) {
  .rewards_mid {
    width: 80%;
  }
}

@media only screen and (max-width: 1200px) {
  .rewards_mid {
    width: 90%;
  }

  .rewards_head h1 {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 1100px) {
  .rewards_mid {
    width: 100%;
  }

  .rewards_head {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .rewards_head h1 {
    font-size: 2.5rem;
  }

  .rewards_cont h1 {
    font-size: 1.6rem;
  
  }
  
  .rewards_cont p { 
    font-size: 0.9rem;
  }
  
}


@media screen and (max-width: 992px) {
  .rewards {
    padding: 1rem 3rem;
    margin-top: 2rem;
  }
  .rewards_head {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .rewards_head h1 {
    font-size: 2.3rem;
  }

  .rewards_cont h1 {
    font-size: 1.4rem;
  
  }
  
  .rewards_cont p { 
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 800px) {
  .r_sqr1, .r_sqr2 {
    display: none;
  }
  .reward_circle {
    width: 100px;
    height: 100px;
  }
  
  .reward_circle h2 {
    font-size: 3rem;  
  }
  
  .rewards_cont h1 {
    font-size: 1.3rem;
  
  }
  
  .rewards_cont p { 
    font-size: 0.8rem;
  }

  .reward_info {
    width: 30%;
  }
  
}

@media screen and (max-width: 600px) {
  .rewards {
    padding: 0 2.5rem;
  }
  .rewards_head h1 {
    font-size: 1.8rem;
    text-align: center;
  }

  .rewards_mid {
    flex-direction: column;
    width: 100%;
    margin-top: 0rem;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .reward_two {
    display: flex;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    margin-top: 0rem;
  }

  .reward_info {
    width: 50%;
  }
}

@media screen and (max-width: 400px) {
  .rewards {
    padding: 0.5rem;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    margin-top: 3rem;
  }

  .reward_info {
    width: 70%;
  }

  
}


.contact {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  grid-gap: 3rem;
  gap: 3rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}

.contact_title {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.contact_title h1 {
  font-family: var(--primary-font);
  font-size: 3rem;
  color: white; 
  text-align: center;
  font-weight: 600;
}

.contact_lines {
  width: 25%;
  pointer-events: none;
}

.contact_list {
  display: flex;
  margin-top: 3rem;
  width: 90%;
  justify-content: space-evenly;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
}

.contact_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #050D18;
  border: 3px solid #FFFFFF;
  border-radius: 15px;
  padding: 1rem;
  font-family: var(--primary-font);
  color: #FFFFFF;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  width: 280px;
}

.contact_info h1 {
  font-size: 1.5rem;
  color: #21F4FE;
  font-weight: 400;
}

.contact_info h2 {
  font-size: 1.3rem;
  font-weight: 500;

}

.contact_soc {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.contact_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 0.5rem;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
}

.contact_icon {
  color: #050D18;
  font-size: 1rem;
  margin-top: 0.1rem;
}

.location {
  display: flex;
  margin-top: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  grid-gap: 2rem;
  gap: 2rem;
}

.location_lt {
   display: flex;
   flex: 0.5 1;
   flex-direction: column;
   justify-content: flex-start;
   font-family: var(--primary-font);
   color: #FFFFFF;
   grid-gap: 1rem;
   gap: 1rem;
}

.location_lt h1{
  font-size: 2.3rem;
  font-weight: 500;
}

.location_lt p {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.9rem;
}

.location_map {
  display: flex;
  flex: 0.5 1;
}

.loc_map {
  width: 300px;
}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {
  .contact_title h1 {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 1100px) {
  .contact_list {
    width: 100%;
  }
  .contact_title {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
  .contact_title h1 {
    font-size: 2.25rem;
  }
  .contact_lines {
    width: 20%;
    pointer-events: none;
}
}

@media screen and (max-width: 992px) {
  .contact_info {
    padding: 0.6rem;
  }

  .contact_info h1 {
    font-size: 1.2rem;
    text-align: center;
  }
  
  .contact_info h2 {
    font-size: 0.9rem;
  }

  .contact_circle {
    padding: 0.4rem;
    width: 30px;
    height: 30px;
  }
  
  .contact_icon {
    color: #050D18;
    font-size: 0.9rem;
  }

  .location {
    justify-content: space-between;
  }

  .location_map {
    width: 90%;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .location {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact_title {
    grid-gap: 1rem;
    gap: 1rem;
  }
  .contact_title h1 {
    font-size: 2.5rem;
  }
  
  .location_lt h1{
    font-size: 2rem;
  }
  
  .location_lt p {
    font-size: 1.1rem;
  }

  .contact_list {
    margin-top: 1rem;
  }

  .contact_lines {
    display: none;
  }

}

@media screen and (max-width: 600px) {
  .contact_title h1 {
    font-size: 1.8rem;
  }  

  .location_lt h1{
    font-size: 1.8rem;
  }
  
  .location_lt p {
    font-size: 1rem;
  }

  .contact_list {
    flex-direction: column;
  }
  .loc_map {
    width: 250px;
}
}

@media screen and (max-width: 400px) {
  .contact {
    margin-top: 0rem;
  }

  .location_lt h1{
    font-size: 1.7rem;
  }
  
  .location_lt p {
    font-size: 0.9rem;
  }


  .contact_info h1 {
    font-size: 1.1rem;
    text-align: center;
  }


  .location_map {
    width: 100%;
  }
}
  

.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3rem;
    grid-gap: 2rem;
    gap: 2rem;
    margin-top: 3rem;
}

.slider_img {
    width: 100px;
    /* box-shadow: 0px 0px 33px 0px rgba(27,176,202,1); */
}

.about_head {
    width: 80%;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1.6rem;
    gap: 1.6rem;
}

.abt_title {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: center;
    width: 100%;
}

.about_head h1 {
    font-family: var(--primary-font);
    font-size: 3rem;
    color: white; 
    text-align: center;
    font-weight: 600;
}

.about_head p {
    font-family: var(--primary-font);
    font-size: 1.3rem;
    color: white; 
    text-align: center;
    font-weight: 500;
}

.blue {
    color: #21F4FE;
} 

.gallery_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.about_slider {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.slider_img {
    width: 80%;
    margin: auto;
}

.achSlider__prev, .achSlider__next {
    font-size: 2rem;
    color: #ffffff;
    cursor: pointer;
}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {
    .about {
        padding: 0.5rem;
        margin-top: 2rem;
    }
    .abt_title {
        grid-gap: 1rem;
        gap: 1rem;
    }

    .about_head h1 {
        font-size: 2.8rem;
    }
    
    .about_head p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 992px) {
    .about_head h1 {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 800px) {
    .about_head h1 {
        font-size: 2.2rem;
    }

    .about_head p {
        font-size: 0.9rem;
    }

    .head_lines {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .slider_img {
        width: 90%;
    }

    .achSlider__prev, .achSlider__next {
        font-size: 1.8rem;
    } 
}

@media screen and (max-width: 400px) {
    .about_head h1 {
        font-size: 1.8rem;
    }

    .about_head p {
        font-size: 0.8rem;
    }

    .slider_img {
        width: 100%;
    }

    .achSlider__prev, .achSlider__next {
        font-size: 1.5rem;
    } 
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}

.benefits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3rem;
    grid-gap: 2rem;
    gap: 2rem;
    margin-top: 3rem;
    position: relative;
}

.b_sqr1, .b_sqr2 {
    position: absolute;
    pointer-events: none;
}

.b_sqr1 {
    left: 5%;
}

.b_sqr2 {
    right: 3%;
    top: 5%;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg);
}

.benefits_content {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1.2rem;
    color: white;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
}

.benefits_point {
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.benefits_para {
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
}

.benefits_icon {
    font-size: 1.3rem;
    color: white;
    flex-shrink: 0;
}

@media screen and (max-width: 992px) {
    .benefits_content {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .b_sqr1, .b_sqr2 {
        display: none;
    }
    .benefits_para {
        font-size: 1rem;
    }

    .benefits_content {
        justify-content: flex-start;
        align-items: flex-start;
    }
}

@media screen and (max-width: 600px) {

    .benefits_para {
        font-size: 0.9rem;
    } 

    .benefits_content {
        margin-top: 0.5rem;
    }

    .benefits {
        padding: 2.5rem;
    }

    .benefits_icon {
        font-size: 1rem;
        color: white;
        flex-shrink: 0;
    }
}

@media screen and (max-width: 400px) {

}
.responsibilities {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3rem;
    grid-gap: 2rem;
    gap: 2rem;
    margin-top: 3rem; 
}

.resp_cont h1 {
    font-size: 1.3rem;
    color: #ffffff;
    font-family: var(--primary-font);
    text-align: center;
    color: #21F4FE;
}

@media screen and (max-width: 600px)  {
    .responsibilities {
        padding: 0 2.5rem;
    }
}
.leaderboard-card {
  height: 100px;
  width: 80%;
  display: flex;
  align-items: center;
  background-color: var(--secondary);
  border: 1px solid #21F4FE;
  border-radius: 1rem;
  margin: 0 auto 1.2rem;
  font-family: var(--primary-font);;
  color: #21F4FE;
  padding: 1rem;
  position: relative;
}

.leaderboard-card > * {
  margin-left: 1.7rem;
}

.leaderboard-card > img {
  height: 46px;
  width: 46px;
  border-radius: 100%;
}

.name-score > h3 {
  margin-right: 1rem;
  font-size: 1.62rem;
}

.name-score {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .leaderboard-card > * {
    margin-left: 1.4rem;
  }
  .leaderboard-card {
    padding: 0.8rem;
    width: 100%;
  }
  .name-score {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin-left: 1rem !important;
  }

  .name-score > h2 {
    font-size: 1.22rem;
  }
  .name-score > h3 {
    line-height: 1.2;
  }
}

li.leaderboard-card > h1 {
  width: 37px !important;
  /* margin-left: 0rem; */
  display: flex;
  flex-shrink: 0;
  /* text-align: center; */
  font-size: 1.6rem;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .leaderboard-card > * {
    margin-left: 0.3rem;
  }
  .leaderboard-card {
    padding: 0.8rem;
  }
  .leaderboard-card > img {
    height: 37px;
    width: 37px;
  }
  .name-score > h2 {
    font-size: 1.10rem;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Roboto", sans-serif; */
  

}

html {
  scroll-behavior: smooth;
}

