.navbar {
    height: 80px;
    position: fixed;
    /* padding: 0 2rem; */
    z-index: 999;
    top: 0;
    width: 100%;
    transition: ease 0.2s;
  }
  .navbar__main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 3rem;  
  }

  .nav_logo img {
    /* height: 100%; */
    width: 130px;
    margin-top: 0.5rem;
  }
  
  .nav_title {
    font-family: var(--primary-font);
    font-size: 1.75rem;
    font-weight: 700;
    color: white;
  }
  
  .nav_contents {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
    /* gap: 2rem; */
  }
  
  .nav__link {
    gap: 2.5rem;
    font-family: var(--primary-font);
    font-style: normal;
    align-items: center;
    color: #ffffff;
    font-size: 1.1rem;
    cursor: pointer;
    opacity: 0.8;
    font-weight: 500;
  }
  
  .nav__link:hover {
    opacity: 1;
  }
  
  a {
    text-decoration: none;
  }
  
  .nav_hamburger {
    display: none;
  }
  
  .avatarIcon {
    cursor: pointer;
  }
  
  .nav__logout_btn{
    background-color: #001C3D;
    color: #ffff;
    display: flex;
    padding: 0.5rem;
    border-radius: 10px;
    border-color: aqua;
    cursor:pointer;
    font-size: 0.9rem;
  }
  
  @media screen and (max-width: 1200px) {
    .nav_contents {
      gap: 1rem;
    }
    .nav__link {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 992px) {
    .nav_hamburger {
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      color: white;
    }
  
    .nav_contents {
      display: none;
    }
  
    .nam_menu_icon {
      font-size: 1.75rem;
      color: var(--tertiary-color);
    }
  
    .navbar__mob {
      background: linear-gradient(180deg, #050D18 82.03%, #001C3D 100%);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 0rem 7rem;
      position: relative;
    }
  
    .navbar_mob_close {
      position: absolute;
      top: 1.7rem;
      right: 2rem;
      font-size: 2rem;
      color: white;
    }

    .close_nav_icon {
      cursor: pointer;
    }
  
    .navbar__mobcontents {
      flex: 0.6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3rem;
    }
  
    .navmob__link {
      gap: 2.5rem;
      font-family: var(--primary-font);
      font-style: normal;
      align-items: center;
      color: #ffffff;
      font-size: 1.3rem;
      cursor: pointer;
      opacity: 0.6;
      text-align: center;
    }
    
  
    .navmob__link:hover {
      opacity: 1;
    }
    .nav__logout_btn{
      background-color: #001C3D;
      color: #ffff;
      display: flex;
      padding: 0.5rem;
      border-radius: 10px;
      border-color: aqua;
      cursor:pointer;
      font-size: 0.9rem;
      text-transform: capitalize;
    }
  }
  
  @media screen and (max-width: 600px) {
    .navbar__main {
      padding: 1.5rem 1rem;
  }
  }
  @media screen and (max-width: 600px) {
    .navbar__mob {
      padding: 0 5rem;
      position: relative;
    }
    .navbar_mob_close {
      position: absolute;
      top: 1.7rem;
      right: 2rem;
      font-size: 2rem;
      color: white;
    }
    
  }