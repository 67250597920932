* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: "Roboto", sans-serif; */
  

}

html {
  scroll-behavior: smooth;
}
