.about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 3rem;
    gap: 2rem;
    margin-top: 3rem;
}

.slider_img {
    width: 100px;
    /* box-shadow: 0px 0px 33px 0px rgba(27,176,202,1); */
}

.about_head {
    width: 80%;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
}

.abt_title {
    display: flex;
    gap: 2rem;
    justify-content: center;
    width: 100%;
}

.about_head h1 {
    font-family: var(--primary-font);
    font-size: 3rem;
    color: white; 
    text-align: center;
    font-weight: 600;
}

.about_head p {
    font-family: var(--primary-font);
    font-size: 1.3rem;
    color: white; 
    text-align: center;
    font-weight: 500;
}

.blue {
    color: #21F4FE;
} 

.gallery_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.about_slider {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.slider_img {
    width: 80%;
    margin: auto;
}

.achSlider__prev, .achSlider__next {
    font-size: 2rem;
    color: #ffffff;
    cursor: pointer;
}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {
    .about {
        padding: 0.5rem;
        margin-top: 2rem;
    }
    .abt_title {
        gap: 1rem;
    }

    .about_head h1 {
        font-size: 2.8rem;
    }
    
    .about_head p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 992px) {
    .about_head h1 {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 800px) {
    .about_head h1 {
        font-size: 2.2rem;
    }

    .about_head p {
        font-size: 0.9rem;
    }

    .head_lines {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .slider_img {
        width: 90%;
    }

    .achSlider__prev, .achSlider__next {
        font-size: 1.8rem;
    } 
}

@media screen and (max-width: 400px) {
    .about_head h1 {
        font-size: 1.8rem;
    }

    .about_head p {
        font-size: 0.8rem;
    }

    .slider_img {
        width: 100%;
    }

    .achSlider__prev, .achSlider__next {
        font-size: 1.5rem;
    } 
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}
