.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(180deg, #050D18 82.03%, #001C3D 100%);
}

.loader_box1 {
    display: flex;
    height: 400px;
    width: 400px;
    justify-content: center;
    align-items: center;
    rotate: 45deg;
    border: 4px solid #FFFFFF;
    box-shadow:  0px 4px 4px #21F4FE;
    /* filter: blur(20px); */
    border-radius: 35px;
}

.loader_box2 {
    display: flex;
    height: 350px;
    width: 350px;
    justify-content: center;
    align-items: center;
    /* rotate: 45deg; */
    border: 2px solid #FFFFFF;
    box-shadow: inset 0px 4px 4px #21F4FE;
    filter: drop-shadow(0px 4px 40px #21F4FE);
    border-radius: 35px;
}

.loader_box2 h1 {
    rotate: -45deg;
    font-family: var(--primary-font);
    color: #FFFFFF;
    font-weight: 600;
    font-size: 2.5rem;
}

@media screen and (max-width: 600px) {
    .loader_box1 {
        height: 300px;
        width: 300px;
    }
    
    .loader_box2 {
        height: 250px;
        width: 250px;
    }
    
    .loader_box2 h1 {
        font-size: 2rem;
    }
}

@media screen and (max-width: 400px) {
    .loader_box1 {
        height: 200px;
        width: 200px;
        border: 2px solid #FFFFFF;

    }
    
    .loader_box2 {
        height: 150px;
        width: 150px;
        border: 1px solid #FFFFFF;

    }
    
    .loader_box2 h1 {
        font-size: 1.5rem;
    }
}
  
@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {

}


@media only screen and (device-width: 768px) {

}



