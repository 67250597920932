@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lakki+Reddy&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-padding: 80px;
  scroll-behavior: smooth;
}

:root {
  --primary-font: 'Montserrat', sans-serif; 
  --secondary-font: 'Nunito Sans', sans-serif; 
}
