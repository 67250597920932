.contact {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 3rem;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
}

.contact_title {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.contact_title h1 {
  font-family: var(--primary-font);
  font-size: 3rem;
  color: white; 
  text-align: center;
  font-weight: 600;
}

.contact_lines {
  width: 25%;
  pointer-events: none;
}

.contact_list {
  display: flex;
  margin-top: 3rem;
  width: 90%;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.contact_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #050D18;
  border: 3px solid #FFFFFF;
  border-radius: 15px;
  padding: 1rem;
  font-family: var(--primary-font);
  color: #FFFFFF;
  gap: 0.7rem;
  width: 280px;
}

.contact_info h1 {
  font-size: 1.5rem;
  color: #21F4FE;
  font-weight: 400;
}

.contact_info h2 {
  font-size: 1.3rem;
  font-weight: 500;

}

.contact_soc {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.contact_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  padding: 0.5rem;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  cursor: pointer;
}

.contact_icon {
  color: #050D18;
  font-size: 1rem;
  margin-top: 0.1rem;
}

.location {
  display: flex;
  margin-top: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
}

.location_lt {
   display: flex;
   flex: 0.5;
   flex-direction: column;
   justify-content: flex-start;
   font-family: var(--primary-font);
   color: #FFFFFF;
   gap: 1rem;
}

.location_lt h1{
  font-size: 2.3rem;
  font-weight: 500;
}

.location_lt p {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.9rem;
}

.location_map {
  display: flex;
  flex: 0.5;
}

.loc_map {
  width: 300px;
}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {
  .contact_title h1 {
    font-size: 2.8rem;
  }
}

@media only screen and (max-width: 1100px) {
  .contact_list {
    width: 100%;
  }
  .contact_title {
    gap: 1.5rem;
  }
  .contact_title h1 {
    font-size: 2.25rem;
  }
  .contact_lines {
    width: 20%;
    pointer-events: none;
}
}

@media screen and (max-width: 992px) {
  .contact_info {
    padding: 0.6rem;
  }

  .contact_info h1 {
    font-size: 1.2rem;
    text-align: center;
  }
  
  .contact_info h2 {
    font-size: 0.9rem;
  }

  .contact_circle {
    padding: 0.4rem;
    width: 30px;
    height: 30px;
  }
  
  .contact_icon {
    color: #050D18;
    font-size: 0.9rem;
  }

  .location {
    justify-content: space-between;
  }

  .location_map {
    width: 90%;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .location {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact_title {
    gap: 1rem;
  }
  .contact_title h1 {
    font-size: 2.5rem;
  }
  
  .location_lt h1{
    font-size: 2rem;
  }
  
  .location_lt p {
    font-size: 1.1rem;
  }

  .contact_list {
    margin-top: 1rem;
  }

  .contact_lines {
    display: none;
  }

}

@media screen and (max-width: 600px) {
  .contact_title h1 {
    font-size: 1.8rem;
  }  

  .location_lt h1{
    font-size: 1.8rem;
  }
  
  .location_lt p {
    font-size: 1rem;
  }

  .contact_list {
    flex-direction: column;
  }
  .loc_map {
    width: 250px;
}
}

@media screen and (max-width: 400px) {
  .contact {
    margin-top: 0rem;
  }

  .location_lt h1{
    font-size: 1.7rem;
  }
  
  .location_lt p {
    font-size: 0.9rem;
  }


  .contact_info h1 {
    font-size: 1.1rem;
    text-align: center;
  }


  .location_map {
    width: 100%;
  }
}
  
