.home_sec {
  min-height: 100vh;
  background-image: url("../../assets/home_bg.svg");
  background-position: center;
  /* background-repeat: no-repeat;  */
  background-size: contain;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
}

.home {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 3rem;
  width: 90%;
}
.referal_btn {
  width: 300px;
  font-family: var(--primary-font);
  font-size: 1.2rem;
  font-weight: 600;
  background: #050912;
  color: #ffff;
  border: 4.1922px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.tmd_icon {
  cursor: pointer;
  color: #ffffff;
  font-size: 1.25rem;
  margin-left: 0.5rem;
  width: 10%;
  flex-shrink: 0;
}

.home_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.holo-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  height: 130px;
  font-family: "Lakki Reddy", cursive;
}
.holo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.holo.holo-1 {
  transform: translateX(150px);
  color: #ffffff;
}
.holo.holo-2 {
  color: #050912;
}
.holo .text {
  position: absolute;
  font-size: 7rem;
  font-weight: bold;
  letter-spacing: 0.5rem;
}

.holo .text.t-left {
  color: #86cbb1;
  transform: translate(-4px, -2px);
}
.holo .text.t-right {
  color: #1295d2;
  transform: translate(4px, 2px);
}

.reg_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  font-family: var(--primary-font);
  width: 220px;
  height: 70px;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #050912;
  border: 4.1922px solid #ffffff;
  border-radius: 10px;
  position: relative;
  z-index: 9;
  transform-style: preserve-3d;
  margin: 1rem 0;
  cursor: pointer;
}

.reg_btn::before {
  content: "";
  position: absolute;
  width: 220px;
  height: 70px;
  border-radius: 10px;
  background: #ffffff;
  z-index: -1;
  top: 10px;
  left: 10px;
  transform: translateZ(-1px);
  cursor: pointer;
}
.amb_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  font-family: var(--primary-font);
  width: 220px;
  height: 60px;
  font-size: 1.15rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #ffffff;
  background: #050912;
  border: 4.1922px solid #ffffff;
  border-radius: 10px;
  position: relative;
  z-index: 9;
  transform-style: preserve-3d;
  margin: 2rem 0;
  cursor: pointer;
}

.reg_btn:hover {
  /* scale: 1.05; */
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);

  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
}

.home_highlights {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features {
  width: 80%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-direction: row;
  font-family: var(--primary-font);
  color: white;
}
.home_highlights h3 {
  font-size: 2rem;
  font-weight: 600;
}
.home_highlights h4 {
  font-size: 1.2rem;
  font-weight: 500;
}

.count_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 5px solid #080c17;
  box-shadow: 0px 2.13071px 9.5882px 4.26142px rgba(255, 255, 255, 0.1);
  transform: translateY(50%);
  background: #ffffff;
  color: #1295d2;
}

.features_cont {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 230px;
  height: 120px;
  border-radius: 10px 10px 0px 0px;
  background: #1295d2;
  padding: 2rem 0;
}

.features_cont h4 {
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center;
  letter-spacing: 0.055em;
  color: #050912;
}

.feature_foot {
  width: 230px;
  height: 20px;
  background: #86cbb1;
  border-top: 5px solid #050912;
  border-radius: 0px 0px 10px 10px;
}

@media only screen and (max-width: 1081px) {
  .count_circle {
    width: 70px;
    height: 70px;
  }
  /*         
    .home{
        padding-top: 2.5rem;
    } */

  .features {
    width: 90%;
  }

  .features_cont {
    width: 210px;
    height: 90px;
  }
  .feature_foot {
    width: 210px;
    height: 20px;
  }

  .home_highlights h3 {
    font-size: 1.5rem;
  }
  .home_highlights h4 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }
  .holo .text {
    font-size: 6rem;
  }
  .holo-container {
    height: 110px;
  }
}
@media only screen and (max-width: 980px) {
  .home {
    flex-direction: column;
    padding-top: 0;
  }
  .mascot {
    height: 37vh;
    width: 50vw;
  }
  .holo.holo-1 {
    transform: translateX(0);
    color: #ffffff;
  }

  .holo .text {
    font-size: 5rem;
  }
  .holo-container {
    height: 90px;
  }
}

@media screen and (max-width: 800px) {
  .main {
    min-height: 100vh;
  }
  .home_sec {
    padding: 0 1.5rem;
  }
  .home {
    max-width: 93vw;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
  }

  .mascot {
    height: 35vh;
    width: 45vw;
    padding: 0;
  }
  .features {
    flex-direction: column;
    align-items: center;
    min-width: 93vw;
    min-height: 35vh;
  }
  .box {
    padding: 2rem 0;
  }
  .home_highlights {
    align-items: center;
  }

  .home_title {
    padding-bottom: 2rem;
  }

  .reg_btn {
    /* width: 150px; */
    font-size: 1rem;
  }
  .reg_btn::before {
    /* width: 150px; */
  }
  .count_circle {
    width: 65px;
    height: 65px;
  }

  .features_cont {
    width: 210px;
    /* height: 65px; */
  }
  .feature_foot {
    width: 210px;
    height: 20px;
  }
  .home_highlights h3 {
    font-size: 1.4rem;
  }
  .home_highlights h4 {
    font-size: 1rem;
    font-weight: 500;
  }
  .holo .text {
    font-size: 3.5rem;
  }
  .holo-container {
    height: 70px;
  }
}
@media only screen and (max-width: 600px) {
  .main {
    min-height: 100vh;
  }
  .reg_btn {
    font-size: 1rem;
  }
  .home {
    max-width: 93vw;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
  }

  .mascot {
    height: 30vh;
    width: 85vw;
    padding: 0;
  }
  .features {
    flex-direction: column;
    align-items: center;
  }

  .count_circle {
    width: 60px;
    height: 60px;
  }

  .features_cont {
    width: 200px;
    /* height: 60px; */
  }
  .feature_foot {
    width: 200px;
    height: 20px;
  }
  .box {
    padding: 2rem 0;
  }
  .home_highlights {
    align-items: center;
  }

  .holo .text {
    font-size: 2.75rem;
  }
  .holo-container {
    height: 50px;
  }
}

@media screen and (max-width: 400px) {
  .main {
    min-height: 100vh;
  }
  .home {
    max-width: 93vw;
    padding-top: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .reg_btn {
    font-size: 1rem;
  }
  .mascot {
    height: 30vh;
    width: 93vw;
    padding: 0;
  }
  .features {
    flex-direction: column;
    align-items: center;
    min-width: 93vw;
  }
  .box {
    padding: 2rem 0;
  }
  .home_highlights {
    align-items: center;
  }

  .count_circle {
    width: 50px;
    height: 50px;
  }

  .features {
    width: 90%;
  }

  .features_cont {
    width: 190px;
    height: 85px;
  }
  .feature_foot {
    width: 190px;
    height: 20px;
  }

  .home_highlights h3 {
    font-size: 1.2rem;
  }
  .home_highlights h4 {
    font-size: 1rem;
  }
}
